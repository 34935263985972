import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import './1_styles/Index.css'
import Loader from './3_pages/Loader'
import { Route, Routes } from 'react-router-dom';
import { HomePage } from './3_pages/HomePage';
import { Nav } from './3_pages/Nav'
import { AboutMePage } from './3_pages/AboutMePage';
import { ContactPage } from './3_pages/ContactPage';
import { WorkPage } from './3_pages/WorkPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HashRouter> {}
      <main id='mainFrameID'>

        <Nav />

        <Loader/>

        <section>

          <Routes>
              <Route path="*" element={<HomePage />} />
              <Route path="about" element={<AboutMePage />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="works" element={<WorkPage />} />
          </Routes>

        </section>

      </main>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
