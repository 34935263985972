import { useEffect, useState } from 'react';
import '../1_styles/Nav.css'
import { useNavigate } from 'react-router-dom';

export function Nav() {

  // states
  // -------------------------------------------------------------------------
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  // -------------------------------------------------------------------------
  
  // function
  // -------------------------------------------------------------------------
  const toggleMenu = () => {
    setIsMenuOpened(prevState => !prevState);
  };

  useEffect(() => {
    if (isMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpened]);

  // navigate
  const navigate = useNavigate();
  const handleClick = (route: string) => {
    return () => {
      navigate(route);
      setIsMenuOpened(false);
      window.scrollTo({ top: 10, behavior: 'smooth' });
    };
  };
  // -------------------------------------------------------------------------
  return (

    <>
  
    <div className='allmenuframe'>

      <button className='menuHambFrame' onClick={() => toggleMenu()}>
        <div className='anchorAll'>
          <div></div>
          <div></div>
        </div>
        <div className='anchorMenuAll'>
          <div className='arrows'></div>
            {
              isMenuOpened? (
                <div className='anchorMenuNeonOff'>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <div className='anchorMenuNeom'>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )
            }
          <p className='menuText'>menu</p>
        </div>
      </button>
    </div>

    {

      isMenuOpened ? (
        <div className='contentCourtain'>
          <div className='optionsFrame'>
            <button className='options' onClick={handleClick('home')}>
              <p>home</p>
              <div className='bottomLine'></div>
            </button>
            <button className='options' onClick={handleClick('about')}>
              <p>about me</p>
              <div className='bottomLine'></div>
            </button>
            <button className='options' onClick={handleClick('works')}>
              <p>works</p>
              <div className='bottomLine'></div>
            </button>
            <button className='options' onClick={handleClick('contact')}>
              <p>contact</p>
              <div className='bottomLine'></div>
            </button>
            <button className='options' onClick={() => {
              window.open('/docs/resume.pdf', '_blank');
              setIsMenuOpened(false);
            }}>
              <p>resume</p>
              <div className='bottomLine'></div>
            </button>
          </div>
        </div>
      ) : null

    }

  </>

  )
}