import '../1_styles/WorkPage.css'
import dataBase from '../4_functions/1_database.json'

interface WorkDatabse {
  work_name: string;
  work_tag: string;
  work_link: string;
}

export function WorkPage() {
  // function
  // -------------------------------------------------------------------------
    const worksArray: WorkDatabse[] = Object.values(dataBase).reverse();
  // -------------------------------------------------------------------------
  return (

    <>
      
      <section id='allContentWorkId'>

        <h1 className='titleWorks'>Works</h1>
        <p className='textWorks'>Check out some works I’ve selected for you to get to know me better.</p>
        <div className='worksFrameAll'>

          {
            worksArray.map((work, index) => (
              <button key={index} className='workUnitFrame' onClick={
                () => window.open(work.work_link, '_blank')
              }>
                <p>{work.work_name}</p>
                <p>{work.work_tag}</p>
                <p>see</p>
              </button>
            ))
          }

        </div>
      </section>

    </>

  )

}