import '../1_styles/AboutMePage.css'

export function AboutMePage() {
  return (

    <>

      <section id='IDsectionAboutMe'>
        <div className='curtainAbout'></div>
        <div id='IdallContentAboutMe'>
          <h1 className='titleAboutMe'>about___me</h1>
          <p>Hey! How’s it going? I’m a software engineer specializing in backend development, though I also have experience in front-end, mobile apps, and UI design. My journey as a programmer began during my first degree in Economics, where I discovered Python and developed machine learning models for data predictions. I’m passionate about photography, technology, gaming, art, science fiction, and math, all of which frequently influence my projects. I’m excited to apply my skills in creating innovative and effective solutions that meet your needs!</p>
        </div>
      </section>

    </>

  )
}