import '../1_styles/ContactPage.css'
import bluesky from '../2_assets/images/homeIconBluesky.svg'
import instagram from '../2_assets/images/homeIconInstagram.svg'
import github from '../2_assets/images/homeIconGitHub.svg'
import linkedin from '../2_assets/images/homeIconLinkedin.svg'
import email from '../2_assets/images/homeIconEmail.svg'
import config from '../4_functions/0_config.json'


export function ContactPage() {

  // states
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // function
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  return (

    <>

      <section id='IdContactPageAll'>

        <div className='stripesContact'></div>

        <div className='contentAll'>
    
          <h1 className='titleContact'>contact</h1>
          <p className='textContact'>If you have any questions, need more information, or want to discuss an opportunity, feel free to reach out to me at <a href={`mailto:${config.emailSendMessages}`}>julioerk@outlook.com</a>. I’m always available to help and would love to chat with you. Let’s work together!</p>
          <div className='socialMediaTxt'>social media</div>
          <div className='findMeContact'>find me around</div>
          <div className='sociaMediaContact'>

            <button  className='socialBTN' onClick={
              () => window.open(config.blueskyProfile, '_blank')
            }>
              <img src={bluesky} alt="bluesky" />
            </button>
            <button  className='socialBTN' onClick={
              () => window.open(config.instagramProfile, '_blank')
            }>
              <img src={instagram} alt="instagram" />
            </button>
            <button  className='socialBTN' onClick={
              () => window.open(config.githubProfile, '_blank')
            }>
              <img src={github} alt="github" />
            </button>
            <button  className='socialBTN' onClick={
              () => window.open(config.linkedinProfile, '_blank')
            }>
              <img src={linkedin} alt="linkedin" />
            </button>
            <button className='socialBTN' onClick={
              () => window.location.href = `mailto:${config.emailSendMessages}`
            }>
              <img src={email} alt="email" />
            </button>

          </div>
        </div>
      </section>

    </>

  )

}