import { useEffect } from 'react';
import '../1_styles/Loader.css'
import loaderSplashBlack1 from '../2_assets/images/loaderSplashBlack1.svg'
import loaderSplashRed1 from '../2_assets/images/loaderSplashRed1.svg'
import loaderSplashAqua1 from '../2_assets/images/loaderSplashAqua1.svg'


export default function Loader() {

    useEffect(() => {
        setTimeout(() => {
            const loaderConf = document.getElementById('allcontentLoaderID');
            loaderConf?.style.setProperty('display', 'none')
        }, 3000);
    }, []);

    return (
        <main className='allcontentLoader' id='allcontentLoaderID'>
            <div className='squareFrame'></div>
            <div className='circleBlack'>
                <img src={loaderSplashAqua1} className='loaderSplashAqua1'/>
                <img src={loaderSplashRed1} className='loaderSplashRed1'/>
                <img src={loaderSplashBlack1} className='loaderSplashBlack1'/>
                <div className='circleBlack'></div>
                <div className='blackInsideCircle'></div>
                <p className='textLoading'>loading</p>
            </div>
        </main>
    )

}